<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card p-4">
        <table
          id="stationInverterAlarmListTable"
          class="table table-sm table-striped table-bordered table-hover w-100"
        >
          <thead>
            <tr>
              <th class="text-center">#</th>
              <th class="text-center">İnvertör ID</th>
              <th class="text-center">Tarih</th>
              <th class="text-center">Statü</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in inverterAlarms" :key="item.id">
              <td>{{ index + 1 }}</td>
              <td class="text-nowrap text-center">{{item.inverter_id}}</td>
              <td class="text-nowrap text-center" :data-order="item.timestamp">
                {{ item.dateConvert }}
              </td>
              <td>
                <v-tooltip bottom v-for="item in item.info" :key="item.index">
                  <template v-slot:activator="{ on }">
                    <p class="alarm-text" v-on="on">{{ item }}</p>
                  </template>
                  <span>{{ item }}</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.alarm-text {
  max-width: 600px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
}
</style>

<script>
import "@/assets/js/functions/datatables.settings";
import $ from "jquery";

export default {
  name: "station_inverter_alarm_list_table",
  data() {
    return {
      table: $("#stationInverterAlarmListTable").DataTable(),
    };
  },
  props: ["inverterAlarms", "fileName"],
  methods: {
    updateTable() {
      this.table.destroy();
      this.$nextTick(() => {
        this.table = $("#stationInverterAlarmListTable").DataTable({
          columnDefs: [{ type: "num", targets: [0, 1] }],
          buttons: [
            {
              extend: "colvis",
              className: "btn-sm btn-primary mr-1",
              text: "Göster&Gizle",
            },
            {
              extend: "excel",
              className: "btn-sm btn-danger",
              title: this.fileName,
              filename: this.fileName,
            },
          ],
        });
      });
    },
  },
  watch: {
    inverterAlarms() {
      this.updateTable();
    },
  },
};
</script>
