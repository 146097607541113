<template>
  <div class="d-inline" v-if="showLastDataWarning">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <i :class="'mr-1 fas fa-exclamation ' + lastDataWarningIcon"></i>
          <span :class="lastDataWarningIcon">
            {{ lastDataTime() }}
          </span>
        </div>
      </template>
      <span>
        Cihazınızdan <b><u>{{ lastDataTimeDiff() }}</u></b> Gündür Veri Alınamıyor!
      </span>
    </v-tooltip>
  </div>
  <div v-else>
    {{ lastDataTime() }}
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>

import moment from "moment/dist/moment";
moment().locale();

export default {
  name: "last_data",
  props: ["time","diff"],
  data() {
    return {
      lastDataWarningIcon: "text-white",
    };
  },
  computed: {
    showLastDataWarning() {
      let day_diff = this.lastDataTimeDiff();
      if (day_diff > this.diff) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    lastDataTimeDiff() {
      let temp_date = new Date(this.time);
      let temp_now = new Date();

      let day_diff =
        (temp_now.getTime() - temp_date.getTime()) / (1000 * 3600 * 24);
      day_diff = Math.floor(day_diff);

      return day_diff;
    },
    lastDataTime() {
      return moment(this.time).format('DD.MM.YYYY HH:mm');
    },
  },
  mounted() {
    if (this.showLastDataWarning) {
      setInterval(() => {
        if (this.lastDataWarningIcon == "text-white") {
          this.lastDataWarningIcon = "text-danger";
        } else {
          this.lastDataWarningIcon = "text-white";
        }
      }, 750);
    }
  },
};
</script>
