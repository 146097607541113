<template>
  <div class="row">
    <div class="col-12">
      <highcharts :options="chartOptions"></highcharts>
      <!-- <div class="total-product-count">
        <h2>Toplam: {{totalConvert}}</h2>
      </div> -->
    </div>
  </div>
</template>

<script>
import { wattConvertor } from "@/assets/js/functions/wattConvertor";
/*import borderRadius from "highcharts-border-radius/index";
import Highcharts from "highcharts";
borderRadius(Highcharts);
import "@/assets/js/functions/highcharts.settings";
const COLUMN_RADIUS = 6;*/
export default {
  name: "station_area_product_graph",
  props: {
    stationData: {
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {},
      seriesData: [],
      total:0
    };
  },
  computed:{
    totalConvert(){
      return wattConvertor(this.total,'h');
    }
  },
  methods: {
    /*createChart() {
      this.chartOptions = {
        chart: {
          type: "column",
        },
        plotOptions: {
          column: {
            borderRadiusTopLeft:COLUMN_RADIUS,
            borderRadiusTopRight:COLUMN_RADIUS,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Üretim (KWh)",
          },
        },
        xAxis: {
          title: {
            text: "",
          },
          categories: [""],
        },
        series: this.seriesData,
        tooltip: {
          formatter: function () {
            var tooltip =
              `<span style="color:` +
              this.series.color +
              `"><b>` +
              this.series.name +
              `:</b> </span><span>` +
              wattConvertor(this.y, "h") +
              `</span>`;
            return tooltip;
          },
        },
      };
    },*/
    createChart() {
      this.chartOptions = {
        chart: {
          type: "pie",
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
        },
        series: {
          name: "Üretim",
          colorByPoint: true,
          data: this.seriesData,
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
        tooltip: {
          formatter: function () {
            var tooltip =
              `<span style="color:` +
              this.color +
              `"><b>` +
              this.series.name +
              `:</b> </span><span>` +
              wattConvertor(this.y, "h") +
              `</span>`;
            return tooltip;
          },
        },
      };
    },
  },
  watch: {
    stationData(data) {
      this.seriesData = [];
      this.total = 0;
      data.forEach((item) => {
        this.seriesData.push({
          name: item.name,
          y: item.total,
        });
        this.total+=item.total;
      });
      this.createChart();
    },
  },
};
</script>
