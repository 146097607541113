<template>
  <div class="main-card card mb-2">
    <div class="card-header">
      <h2>
        {{stationName}} İnvertörleri
        <a @click="showInverters = !showInverters">
          <i v-if="showInverters" class="fas fa-chevron-down card-toggle-button"></i>
          <i v-else class="fas fa-chevron-right card-toggle-button"></i>
        </a>
      </h2>
    </div>
    <div class="card-body py-4" v-show="showInverters">
      <div class="row">
        <div class="col-12">
          <div class="report-body">
            <div
              class="report-loading card py-3 mx-auto"
              v-if="reportLoading"
            >
              <h3>
                İnvertörler Yükleniyor
                <br />
                Lütfen Bekleyiniz
                <i class="fas fa-spinner fa-pulse"></i>
              </h3>
            </div>
            <station-inverters-table
              v-show="reportLoading == false"
              :inverters-data="invertersData"
              :file-name="stationName+' İnvertörleri'"
            ></station-inverters-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import StationInvertersTable from "./StationInvertersTable.vue";

export default {
  components: { StationInvertersTable },
  name: "station_inverters_product_card",
  props:{
    stationName:{
      required:true
    },
    invertersData:{
      required:true
    }
  },
  data() {
    return {
      showInverters: true, // Kart Göster&Gizle
      reportLoading: true,
    };
  },
};
</script>
