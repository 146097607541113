<template>
  <div>
    <today-info
      :info-data="infoData"
      :today-data="stationDailyTotal"
      :total-data="getStationTotal"
    ></today-info>

    <hr class="my-5" />
    
    <template v-if="selected_devices.length > 0">
      <device-card :items="selected_devices"></device-card>
      <hr class="my-5" />
    </template>

    <station-product-card
      :station-name="powerStationsInfo.name"
    ></station-product-card>

    <hr class="my-5" />

    <station-area-product-card
      :station-name="powerStationsInfo.name"
    ></station-area-product-card>

    <hr class="my-5" />

    <station-inverters-card
      :stationName="powerStationsInfo.name"
      :inverters-data="stationInvertersInfo"
    ></station-inverters-card>

    <hr class="my-5" />

    <station-inverter-alarm-list-card
      :stationName="powerStationsInfo.name"
    ></station-inverter-alarm-list-card>
  </div>
</template>

<script>
import {
  GET_STATION,
  GET_STATION_DAILY_TOTAL,
  GET_STATION_TOTAL,
  RESET_STATION_DAILY_TOTAL,
} from "@/core/services/store/stations.module";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_INVERTERS } from "@/core/services/store/inverters.module";
import TodayInfo from "../../custom/info/TodayInfo.vue";
import DeviceCard from "../../custom/device/DeviceCard.vue";
import StationAreaProductCard from "./components/StationAreaProduct/StationAreaProductCard.vue";

import StationInvertersCard from "./components/StationInverters/StationInvertersCard.vue";
import StationProductCard from "./components/StationProduct/StationProductCard.vue";
import StationInverterAlarmListCard from './components/StationInverterAlarmList/StationInverterAlarmListCard.vue';

import moment from "moment/moment";
moment().locale();

export default {
  name: "stations.show",
  components: {
    TodayInfo,
    DeviceCard,
    StationAreaProductCard,
    StationInvertersCard,
    StationProductCard,
    StationInverterAlarmListCard,
  },
  data() {
    return {
      powerStationsInfo: [],
      stationAreasInfo: [],
      stationInvertersInfo: [],
      //Tanımlı Cihazlar
      selected_devices: [],
      infoData: {
        header: ["Adı", "Sınıflandırma", "PV Kapasitesi", "Adres"],
        data: [],
        ready: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "getStationDailyTotal",
      "getStationTotal",
    ]),
    getImage() {
      return process.env.BASE_URL + "media/images/ges1.jpg";
    },
    stationDailyTotal() {
      let id = this.$route.params.id;
      let sDTcookie = this.$cookies.get("getStationDailyTotal_" + id);
      if (sDTcookie) {
        return sDTcookie;
      } else {
        let cookieData = this.getStationDailyTotal;
        if (cookieData != 0) {
          this.$cookies.set("getStationDailyTotal_" + id, cookieData, "1min");
          this.$store.dispatch(RESET_STATION_DAILY_TOTAL);
          return cookieData;
        } else {
          return "";
        }
      }
    },
  },
  created() {
    this.$store
      .dispatch(GET_STATION, this.$route.params.id)
      .then((res) => {
        this.powerStationsInfo = res.powerStationsInfo;
        //Detay Verilerini Getirme
        this.infoData.data = [
          this.powerStationsInfo.name,
          this.powerStationsInfo.power_station_type_name,
          this.powerStationsInfo.installed_power,
          this.powerStationsInfo.county + "/" + this.powerStationsInfo.city,
        ];
        this.infoData.ready = true;
        //Tanımlı Cihazları Getirme
        if (this.powerStationsInfo.selected_devices) {
          this.selected_devices =
            this.powerStationsInfo.selected_devices.split(";");
        } else {
          this.selected_devices = [];
        }
        this.stationAreasInfo = res.stationFieldsInfo;
      })
      .then(() => {
        this.$store.dispatch(GET_INVERTERS).then((res) => {
          this.stationAreasInfo.forEach((el) => {
            res.forEach((e) => {
              if (el.id == e.station_field_id) {
                this.stationInvertersInfo.push(e);
              }
            });
          });
        });
      })
      .then(() => {
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Santraller", route: "/stations", icon: "flaticon-car" },
          { title: this.powerStationsInfo.name },
        ]);

        //this.$store.dispatch(GET_STATION_DAILY_TOTAL, {id: this.$route.params.id, begin_date: this.selectedDateDaily})

        let id = this.$route.params.id;
        let sDTcookie = this.$cookies.get("getStationDailyTotal_" + id);
        if (sDTcookie == null) {
          let begin_date = moment().format("DD.MM.YYYY");
          this.$store.dispatch(GET_STATION_DAILY_TOTAL, { id, begin_date });
        }

        this.$store.dispatch(GET_STATION_TOTAL, { id: this.$route.params.id });
      });
  },
};
</script>
