<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card p-4">
        <table
          id="stationAreaProductTable"
          class="table table-sm table-striped table-bordered table-hover w-100"
        >
          <thead>
            <tr>
              <th class="text-center">#</th>
              <th class="text-center">Saha Adı</th>
              <th class="text-center">Üretim</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in stationData" :key="item.id">
              <td>{{ index + 1 }}</td>
              <td class="text-nowrap text-center">{{ item.name }}</td>
              <td
                class="text-nowrap text-right"
                :data-order="item.total.toFixed(2)"
              >
                {{ item.total_convert }}
              </td>
            </tr>
          </tbody>
          <!-- <tfoot>
            <tr>
              <td colspan="3" class="text-end">Toplam: {{ totalConvert }}</td>
            </tr>
          </tfoot> -->
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { wattConvertor } from "@/assets/js/functions/wattConvertor";
import "@/assets/js/functions/datatables.settings";
import $ from "jquery";

export default {
  name: "station_area_product_table",
  data() {
    return {
      table: $("#stationAreaProductTable").DataTable(),
      total: 0,
    };
  },
  props: ["stationData", "fileName"],
  computed: {
    totalConvert() {
      return wattConvertor(this.total, "h");
    },
  },
  methods: {
    updateTable() {
      this.table.destroy();
      this.$nextTick(() => {
        this.table = $("#stationAreaProductTable").DataTable({
          columnDefs: [
            { type: "num", targets: [0, 2] },
            /*{ width:'10%', targets:0 },
            { width:'70%', targets:1 },
            { width:'20%', targets:2 },*/
          ],
          buttons: [
            {
              extend: "colvis",
              className: "btn-sm btn-primary mr-1",
              text: "Göster&Gizle",
            },
            /*{
              extend: "pdf",
              className: "btn-sm btn-danger",
              pageSize: "A4",
              title: this.fileName,
              filename: this.fileName,
              customize: function (doc) {
                doc.pageMargins = [60, 20, 60, 20];
                doc.defaultStyle.fontSize = 14;
                doc.styles.tableHeader.fontSize = 14;
              },
            },*/
            {
              extend: "excel",
              className: "btn-sm btn-danger",
              title: this.fileName,
              filename: this.fileName,
            },
          ],
        });
      });
    },
  },
  watch: {
    stationData(data) {
      this.total = 0;
      data.forEach((item) => {
        this.total += item.total;
      });
      this.updateTable();
    },
  },
};
</script>
