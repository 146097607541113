<template>
  <div class="row">
    <div class="col-12">
      <highcharts :options="chartOptions"></highcharts>
      <!-- <div class="total-product-count">
        <h2>Toplam: {{totalConvert}}</h2>
      </div> -->
    </div>
  </div>
</template>

<script>
import { wattConvertor } from "@/assets/js/functions/wattConvertor";

import "@/assets/js/functions/highcharts.settings";

import moment from "moment/dist/moment";
import '@/assets/js/functions/moment.settings';

export default {
  name: "station_product_graph",
  props: {
    stationData: {
      required: true,
    },
    dateType:{
      required: true
    }
  },
  data() {
    return {
      chartOptions: {},
      seriesData: [],
      total: 0,
    };
  },
  computed: {
    totalConvert() {
      return wattConvertor(this.total, "h");
    },
    dateLabelFormat(){
      if(this.dateType == 0){
        return "{value:%e %B}";
      }
      else{
        return "{value:%B}"
      }
    }
  },
  methods: {
    createChart() {
      this.chartOptions = {
        chart: {
          type: "column",
        },
        legend: {
          enabled: false,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Üretim (KWh)",
          },
        },
        xAxis: {
          labels: {
            format: this.dateLabelFormat,
          },
          type: "datetime",
        },
        series: [
          {
            data: this.seriesData
          },
        ],
        tooltip: {
          formatter: function () {
            var tooltip =
              `<span style="color:` +
              this.series.color +
              `"><b>` +
              moment(this.x).format("DD MMMM") +
              `:</b> </span><span>` +
              wattConvertor(this.y, "h") +
              `</span>`;
            return tooltip;
          },
        },
      };
    },
  },
  watch: {
    stationData(data) {
      this.seriesData = [];
      data.forEach((item) => {
        this.seriesData.push({
          x: item.timestampx,
          y: item.total
        });
      });
      this.createChart();
    },
  },
};
</script>
