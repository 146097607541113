<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card p-4">
        <table
          id="stationInvertersTable"
          class="table table-sm table-striped table-bordered table-hover w-100"
        >
          <thead>
            <tr>
              <th class="text-center text-nowrap">#</th>
              <th class="text-center text-nowrap">İşlem</th>
              <th class="text-center text-nowrap">İnvertör ID</th>
              <th class="text-center text-nowrap">Son Veri Zamanı</th>
              <th class="text-center text-nowrap">İnvertör Tipi</th>
              <th class="text-center text-nowrap">Saha Adı</th>
              <th class="text-center text-nowrap">Datalogger</th>
              <th class="text-center text-nowrap">Bağlı Panel Sayısı</th>
              <th class="text-center text-nowrap">
                Bağlı Panellerin Toplam Gücü
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in invertersData" :key="item.id">
              <td>{{ index + 1 }}</td>
              <td class="text-nowrap text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <router-link
                        :to="{
                          name: 'inverters.show',
                          params: { id: item.id },
                        }"
                      >
                        <v-btn class="mx-2 bg-info" fab dark x-small>
                          <v-icon dark>mdi-eye</v-icon>
                        </v-btn>
                      </router-link>
                    </span>
                  </template>
                  <span>Göster</span>
                </v-tooltip>
              </td>
              <td class="text-nowrap">
                {{ item.inverter_id }}
              </td>
              <td class="text-center text-nowrap" :data-order="item.timestamp">{{item.dateConvert}}</td>
              <td class="text-center text-nowrap">
                {{ item.brand }} - {{ item.model }}
              </td>
              <td class="text-nowrap text-center">
                {{ item.station_field_name }}
              </td>
              <td class="text-nowrap text-center">
                {{ item.datalogger_id }}
              </td>
              <td class="text-nowrap text-right">
                {{ item.connected_panel_count }}
              </td>
              <td class="text-nowrap text-right">
                {{ item.connected_panel_total_power }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
const EXPORT_COLUMNS = [0, 2, 3, 4, 5, 6, 7, 8]; //Excel&PDF sütunlara göre yazdırma

import "@/assets/js/functions/datatables.settings";
import $ from "jquery";

export default {
  name: "station_inverters_table",
  data() {
    return {
      table: $("#stationInvertersTable").DataTable(),
      areaData: null,
    };
  },
  props: ["invertersData", "fileName"],
  methods: {
    updateTable() {
      this.table.destroy();
      this.$nextTick(() => {
        this.table = $("#stationInvertersTable").DataTable({
          columnDefs: [
            { type: "num", targets: [0,3] },
            { orderable: false, targets: 1 },
          ],
          buttons: [
            {
              extend: "colvis",
              className: "btn-sm btn-primary mr-1",
              text: "Göster&Gizle",
            },
            /*{
              extend: "pdf",
              className: "btn-sm btn-danger",
              pageSize: "A4",
              title: this.fileName,
              filename: this.fileName,
              customize: function (doc) {
                doc.pageMargins = [60, 20, 60, 20];
                doc.defaultStyle.fontSize = 14;
                doc.styles.tableHeader.fontSize = 14;
              },
              exportOptions:{
                columns:EXPORT_COLUMNS
              }
            },*/
            {
              extend: "excel",
              className: "btn-sm btn-danger",
              title: this.fileName,
              filename: this.fileName,
              exportOptions: {
                columns: EXPORT_COLUMNS,
              },
            },
          ],
        });
        this.$parent.$data.reportLoading = false;
      });
    },
  },
  watch: {
    invertersData() {
      this.updateTable();
    },
  },
};
</script>
