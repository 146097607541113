<template>
  <div class="main-card card">
    <div class="card-header">
      <h2>
        Tanımlı Cihaz Bilgileri
        <a @click="showDeviceInfo = !showDeviceInfo">
          <i
            v-if="showDeviceInfo"
            class="fas fa-chevron-down card-toggle-button"
          ></i>
          <i v-else class="fas fa-chevron-right card-toggle-button"></i>
        </a>
      </h2>
    </div>
    <div class="card-body" v-show="showDeviceInfo">
      <!-- Eğer Tanımlı Cihaz Varsa -->
      <!-- Cihazlar Getirildiği Zaman -->
      <div class="row" v-if="deviceReady">
        <div
          class="col-12 col-md-4 col-lg-3 device-card-body"
          v-for="(item, index) in devices"
          :key="item.id"
        >
          <div
            class="rounded-xl device-card p-5"
            :style="'background-color:' + item.color"
          >
            <div class="category-icon d-flex mb-2">
              <i :class="item.icon + ' text-white'"></i>
              <h3 class="text-white ml-1" v-if="item.device_category != 11">
                {{ item.category_name }}
              </h3>
              <h4 class="text-white ml-1" v-else>{{ item.category_name }}</h4>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    class="info-data-icon"
                    :href="item.href"
                    v-on="on"
                    target="_blank"
                  >
                    <i class="fas fa-info-circle"></i>
                  </a>
                </template>
                <span> Detay </span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    class="refresh-data-icon"
                    @click="refreshData(index, item.value)"
                    v-on="on"
                  >
                    <i class="fas fa-sync-alt"></i>
                  </a>
                </template>
                <span> Yenile </span>
              </v-tooltip>
            </div>
            <device-card-detail
              :device_href="item.href"
              :measuring_device_id="item.value"
              :device_category="item.device_category"
              ref="last_data_component"
            ></device-card-detail>
          </div>
        </div>
      </div>
      <!-- Cihazlar Getirilirken -->
      <div class="row" v-else>
        <div class="col-12 text-center device-coming">
          Cihazlarınız Getiriliyor
          <i class="fas fa-spinner fa-pulse mx-1"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.device-card-body {
  padding: 12.5px;
  .device-card {
    height: 320px;
    width: 100%;
  }
}
.category-icon {
  width: 100%;
  align-items: center;
  i {
    font-size: 2.5rem;
  }
  h3 {
    margin: 0;
    padding: 0;
  }
  .refresh-data-icon {
    display: flex;
    position: absolute;
    right: 0;
    transform: translate(-100%, 0);
    i {
      color: white;
      font-size: 1.75rem;
    }
    &:hover {
      i {
        color: #bdc3c7;
      }
    }
  }
  .info-data-icon {
    display: flex;
    position: absolute;
    right: 15%;
    transform: translate(-75%, 0);
    i {
      color: white;
      font-size: 1.75rem;
    }
    &:hover {
      i {
        color: #bdc3c7;
      }
    }
  }
}
.device-coming {
  font-size: 18px;
  i {
    font-size: 18px;
    color: black;
  }
}
.mt-35px {
  margin-top: 35px;
}
</style>

<script>
import setDeviceTheme from "@/assets/js/functions/set_device_theme.js";
import DeviceCardDetail from "./DeviceCardDetail";
export default {
  name: "device_card",
  props: ["items"],
  components: { DeviceCardDetail },
  data() {
    return {
      showDeviceInfo: true,
      deviceReady: false,
      devices: [],
    };
  },
  methods: {
    setDeviceInfo() {
      this.deviceReady = false;
      this.devices = [];
      this.items.forEach((item) => {
        let device_info = item.split(":");
        let measuring_device_id = device_info[0];
        let device_category = parseInt(device_info[1]);
        let theme = setDeviceTheme.set(device_category);
        this.devices.push({
          value: measuring_device_id,
          href: theme.href + measuring_device_id,
          color: theme.color,
          icon: theme.icon,
          category_name: theme.category_name,
          device_category,
        });
      });
      this.deviceReady = true;
    },
    refreshData(index, measuring_device_id) {
      this.$refs.last_data_component[index].getLastDatas(measuring_device_id);
    },
  },
  created() {
    this.setDeviceInfo();
  },
};
</script>
