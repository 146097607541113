<template>
  <div class="device-info">
    <device-location :location="locationName" :measuring_device_id="measuring_device_id"></device-location>
    <hr class="white">
    <div class="row data-area" v-if="[1, 2, 3].includes(device_category)">
      <div class="col-12" v-if="gettingLastDatas">
        <h3 class="text-white text-center">Veriler Yükleniyor</h3>
        <i class="fas fa-spinner fa-pulse text-white ml-1"></i>
      </div>
      <div class="col-12" v-else>
        <table class="table table-sm text-white" v-if="instant_values != null">
          <thead>
            <tr>
              <th class="text-center">Değerler</th>
              <th class="text-center">L1</th>
              <th class="text-center">L2</th>
              <th class="text-center">L3</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="text-center">Gerilim (V)</th>
              <td class="text-right">
                {{ instant_values.voltage_l1.toFixed(1) }}
              </td>
              <td class="text-right">
                {{ instant_values.voltage_l2.toFixed(1) }}
              </td>
              <td class="text-right">
                {{ instant_values.voltage_l3.toFixed(1) }}
              </td>
            </tr>
            <tr>
              <th class="text-center">Akım (A)</th>
              <td class="text-right">
                {{ instant_values.current_l1.toFixed(1) }}
              </td>
              <td class="text-right">
                {{ instant_values.current_l2.toFixed(1) }}
              </td>
              <td class="text-right">
                {{ instant_values.current_l3.toFixed(1) }}
              </td>
            </tr>
            <tr>
              <th class="text-center">Aktif Güç (kW)</th>
              <td class="text-right">
                {{ instant_values.aktif_l1.toFixed(1) }}
              </td>
              <td class="text-right">
                {{ instant_values.aktif_l2.toFixed(1) }}
              </td>
              <td class="text-right">
                {{ instant_values.aktif_l3.toFixed(1) }}
              </td>
            </tr>
            <tr>
              <th class="text-center">Son Veri Zamanı</th>
              <td colspan="3" class="text-center last-data-text">
                <last-data
                  :time="instant_values.date_record"
                  diff="1"
                ></last-data>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="col-12" v-else>
          <div v-if="gettingDataMessage != ''">
            <h3 class="text-white text-center">
              {{ gettingDataMessage }}
            </h3>
          </div>
          <div v-else>
            <h3 class="text-white text-center">
              Cihazın Son Verisine Ulaşılamadı!
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="row data-area" v-else-if="[4, 5].includes(device_category)">
      <div class="col-12" v-if="gettingIODatas">
        <h3 class="text-white text-center">Veriler Yükleniyor</h3>
        <i class="fas fa-spinner fa-pulse text-white ml-1"></i>
      </div>
      <template v-else-if="ioDatas != null">
        <div class="col-12">
          <div class="row io-data">
            <div
              class="col-12"
              v-for="(item, index) in ioDatas.module_status"
              :key="item.id"
            >
              <span>{{ index + 1 }})</span>
              <div class="module-status mx-1">
                <i class="fas fa-power-off mx-2" :style="'color:'+ioDatas.module_colors[index]"></i>
              </div>
              <p v-if="ioDatas.module_names[index].length < 20">
                ({{ showModuleName(ioDatas.module_names[index]) }})
              </p>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{on}">
                  <p v-on="on">
                    ({{ showModuleName(ioDatas.module_names[index]) }})
                  </p>
                </template>
                <span>
                  {{ioDatas.module_names[index]}}
                </span>
              </v-tooltip>
            </div>
          </div>
        </div>
        <div class="col-12" style="margin-top:0.5rem">
          <table class="table table-sm text-white" v-if="ioDatas != null">
            <tr>
              <th>Son Veri Zamanı</th>
              <td class="last-data-text">
                <last-data
                  :time="ioDatas.last_data_time"
                  diff="1"
                ></last-data>
              </td>
            </tr>
          </table>
        </div>
      </template>
      <div class="col-12" v-else>
        <div v-if="gettingDataMessage != ''">
          <h3 class="text-white text-center">
            {{ gettingDataMessage }}
          </h3>
        </div>
        <div v-else>
          <h3 class="text-white text-center">
            Cihazın Modül Verilerine Ulaşılamadı!
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.device-info {
  p {
    color: white;
    font-size: 14px;
    margin: 0;
  }
  b {
    margin-right: 0.5rem;
  }
  a {
    color: #f5f6fa;
  }
}
hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
th {
  margin: 1px;
  padding: 0;
  vertical-align: middle;
}
table {
  thead {
    th {
      margin: 1px;
      padding: 0;
    }
  }
  tbody {
    tr {
      td {
        font-size: 80%;
      }
    }
  }
}
.data-area {
  height: 220px;
  padding: 15px;
  .col-12 {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 1.5rem;
    }
    h3 {
      margin: 0;
    }
  }
}

.last-data-text {
  font-size: 1rem;
  div {
    font-size: 1rem;
    i {
      font-size: 1rem;
    }
    span {
      font-size: 1rem;
    }
  }
}

.io-data {
  margin: 0;
  padding: 0;
  color: white;
  height: 175px;
  overflow-y: auto;
  .col-12 {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: start !important;
    align-items: center;
    p {
      margin: 0;
      padding: 0;
      font-size: 1rem;
      font-weight: 600;
    }
    i {
      font-size: 1.25rem;
      //background: radial-gradient(white 50%, transparent 50%);
    }
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($color: #fff, $alpha: 0.7);
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #bdc3c7;
  }
}
.module-status{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
}
</style>

<script>
import {
  GET_DEVICE_LAST_DATAS_123,
  GET_DEVICE_LAST_DATAS_45,
} from "@/core/services/store/devices.module";
import DeviceLocation from "./DeviceLocation.vue";
import LastData from "./LastData.vue";
export default {
  name: "device_card_detail",
  props: ["measuring_device_id", "device_category"],
  components: {
    DeviceLocation,
    LastData,
  },
  data() {
    return {
      gettingLastDatas: true,
      instant_values: [],
      gettingDataMessage: "",
      ioDatas: [],
      locationName: "",
      gettingIODatas: true,
    };
  },
  methods: {
    getLastDatas(measuring_device_id) {
      this.gettingLastDatas = true;
      this.gettingIODatas = true;
      switch (this.device_category) {
        //Sayaç-Röle-Analizör İçin
        case 1:
        case 2:
        case 3:
          this.$store
            .dispatch(GET_DEVICE_LAST_DATAS_123, { measuring_device_id })
            .then((response) => {
              if (response.data) {
                let instant_values = response.data.instant_values;
                if (instant_values.length != "") {
                  this.instant_values = instant_values;
                } else {
                  this.instant_values = null;
                }
                this.locationName = response.data.device_info.location_name;
              } else {
                this.instant_values = null;
                this.gettingDataMessage = response.message;
              }
              this.gettingLastDatas = false;
            });
          break;
        //Giriş-Çıkış Modülleri İçin
        case 4:
        case 5:
          const DEFAULT_ON_COLOR = '#41833A' ;
          const DEFAULT_OFF_COLOR = '#F5F5F5';
          this.$store
            .dispatch(GET_DEVICE_LAST_DATAS_45, { measuring_device_id })
            .then((response) => {
              if (response.data) {
                if (response.data.io_modules[0]) {
                  if (response.data.io_modules[0].payment_state != "close") {
                    this.ioDatas = response.data.io_modules[0];
                    if (this.device_category == 4) {
                      this.ioDatas.module_names = [];
                      this.ioDatas.module_colors = [];
                      this.ioDatas.module_status = this.ioDatas.input_status;
                      this.ioDatas.module_status.forEach((item,index)=>{
                        if(item == 1){
                          this.ioDatas.module_names.push(this.ioDatas.input_name[index]);
                          this.ioDatas.module_colors.push(this.ioDatas.input_on[index]);
                        }
                        else{
                          this.ioDatas.module_names.push(this.ioDatas.input_name2[index]);
                          this.ioDatas.module_colors.push(this.ioDatas.input_off[index]);
                        }
                      });
                    } else {
                      this.ioDatas.module_names = this.ioDatas.output_name;
                      this.ioDatas.module_status =
                        this.ioDatas.output_desired_status;
                      this.ioDatas.module_colors = [];
                      this.ioDatas.module_status.forEach(item=>{
                        if(item == 1 ){
                          this.ioDatas.module_colors.push(DEFAULT_ON_COLOR);
                        }
                        else{
                          this.ioDatas.module_colors.push(DEFAULT_OFF_COLOR);
                        }
                      });
                    }
                  } else {
                    this.ioDatas = null;
                    this.gettingDataMessage = "Ödemesi Yapılmamış Cihaz!";
                  }
                  this.locationName = response.data.io_modules[0].location;
                } else {
                  this.ioDatas = null;
                  this.gettingDataMessage = "Cihaz Verilerine Ulaşılamıyor!";
                }
              } else {
                this.ioDatas = null;
                this.gettingDataMessage = response.message;
              }
              this.gettingIODatas = false;
            });
          break;
      }
    },
    showModuleName(module_name) {
      const SUB_LIMIT = 20;
      if (module_name.length > SUB_LIMIT) {
        module_name = module_name.substr(0, SUB_LIMIT - 1) + "...";
      }
      return module_name != "" ? module_name : "---";
    },
  },
  created() {
    this.getLastDatas(this.measuring_device_id);
  },
  mounted() {
    setInterval(() => {
      this.getLastDatas(this.measuring_device_id);
    }, 60 * 1000);
  },
};
</script>