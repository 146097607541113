var setDeviceTheme = {
  set(device_category) {
    let icon = '';
    let color = '';
    let category_name = '';
    let href = '';
    switch (device_category) {
      case 1:
        category_name = 'Sayaç';
        icon          = 'fas fa-tachometer-alt';
        color         = '#2980B9';
        href          = 'https://www.enerjitakibi.com/instantData.php?id=';
        break;
      case 2:
        category_name = 'Röle';
        icon          = 'fas fa-broadcast-tower';
        color         = '#7d5fff';
        href          = 'https://www.enerjitakibi.com/instantValues.php?id=';
        break;
      case 3:
        category_name = 'Analizör';
        icon          = 'fas fa-bolt';
        color         = '#5B2C6F';
        href          = 'https://www.enerjitakibi.com/instantValues.php?id=';
        break;
      case 4:
        category_name = 'Giriş Modülü';
        icon          = 'fas fa-toggle-on';
        color         = '#99cccc';
        href          = 'https://www.enerjitakibi.com/io/History/Graph.php?commDeviceId=';
        break;
      case 5:
        category_name = 'Çıkış Modülü';
        icon          = 'fas fa-toggle-off';
        color         = '#ff6633';
        href          = 'https://www.enerjitakibi.com/io/History/Graph.php?commDeviceId=';
        break;
      case 6:
        category_name = 'Işık Sensörü';
        icon          = 'fas fa-lightbulb';
        color         = '#fbc531';
        href          = '';//Şimdilik Yok
        break;
      case 7:
        category_name = 'Doğalgaz Sayacı';
        icon          = 'fas fa-fire';
        color         = '#F79F1F';
        href          = '';//Şimdilik Yok
        break;
      case 8:
        category_name = 'Debimetre';
        icon          = 'fas fa-faucet';
        color         = '#4b4b4b';
        href          = '';//Şimdilik Yok
        break;
      case 9:
        category_name = 'Hacim Düzeltici';
        icon          = 'fas fa-ruler';
        color         = '#3d3d3d';
        href          = '';//Şimdilik Yok
        break;
      case 10:
        category_name = 'Sayıcı';
        icon          = 'fas fa-calculator';
        color         = '#ff793f';
        href          = '';//Şimdilik Yok
        break;
      case 11:
        category_name = 'Analog Giriş Modülü';
        icon          = 'fas fa-rss';
        color         = '#a5b1c2';
        href          = '';//Şimdilik Yok
        break;
      case 12:
        category_name = 'Jeneratör';
        icon          = 'fab fa-ioxhost';
        color         = '#4b6584';
        href          = '';//Şimdilik Yok
        break;
      case 13:
        category_name = 'Su Sayacı';
        icon          = 'fas fa-tint';
        color         = '#2c2c54';
        href          = '';//Şimdilik Yok
        break;
      case 14:
        category_name = 'Astronomik Röle';
        icon          = 'fas fa-user-astronaut';
        color         = '#84817a';
        href          = '';//Şimdilik Yok
        break;
      default:
        category_name = 'Bilinmiyor';
        icon          = 'fas fa-question-circle';
        color         = '#81ecec';
        href          = '';//Şimdilik Yok
        break;
    }

    let theme = {
      icon,
      color,
      category_name,
      href,
    }

    return theme;
  },
}

export default setDeviceTheme