<template>
  <div class="col-12">
    <span class="location-name" v-if="location.length == 0">{{ measuring_device_id }}</span>
    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on }">
        <p v-on="on" class="location-name">{{ location }}</p>
        <span class="device-id"> ({{ measuring_device_id }})</span>
      </template>
      <span>{{ location }}</span>
    </v-tooltip>
  </div>
</template>

<style lang="scss" scoped>
.col-12 {
  margin: 0;
  padding: 0;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  .location-name {
    width: 60%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0;
  }
  .device-id {
    color: white;
  }
}
</style>

<script>
export default {
  name: "device_location",
  props: ["location", "measuring_device_id"],
};
</script>
